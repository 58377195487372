import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { mergeMapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FirebaseMessagingService {
  constructor(private afMessaging: AngularFireMessaging) { }

  requestPermission() {
    return this.afMessaging.requestToken.pipe(
      mergeMapTo(this.afMessaging.tokenChanges),
    );
  }

  receiveMessage() {
    return this.afMessaging.messages;
  }
}