import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/compat/analytics';
import { environment } from 'src/environments/environment';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFirePerformanceModule, PerformanceMonitoringService, AngularFirePerformance } from '@angular/fire/compat/performance';

@NgModule({
    imports: [
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireAnalyticsModule,
        AngularFirePerformanceModule,
    ],
    exports: [
        AngularFireModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireAnalyticsModule,
        AngularFirePerformanceModule
    ],
    providers: [
        PerformanceMonitoringService
    ]
})
export class FirebaseModule { }
