import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { AppConfigurationCommand } from '../commands/configuration/configuration.command';
import { AppConfigurationModel } from '../models/configuration/app-configuration.model';

@Injectable({
    providedIn: 'root',
})
export class MaintenceGuard implements CanActivate {
    configuration: AppConfigurationModel;

    constructor(private router: Router,
        private configurationCommand: AppConfigurationCommand) { }

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        await this.configurationCommand.get().then((result: AppConfigurationModel) => {
            this.configuration = result;
            
        });

        this.checkMaintence();

        if (!this.configuration.under_maintence) {
            this.router.navigateByUrl("/home");
            return false
        }
        return true;
    }

    checkMaintence() {
        if (this.configuration && this.configuration.under_maintence) {
            localStorage.setItem('maintence', 'Y');
        } else {
            localStorage.setItem('maintence', 'N');
        }
    }
}
