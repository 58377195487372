import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AnonymousGuard } from './guards/anonymous.guard';
import { LoggedGuard } from './guards/logged.guard';
import { MaintenceGuard } from './guards/maintence.guard';
import { SplashGuard } from './guards/splash.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full'
  },
  {
    path: 'home',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    canActivate: [AnonymousGuard],
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'gestao-riscos',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/operational-profile/loss-management/loss-management.module').then( m => m.LossManagementPageModule)
  },
  {
    path: 'tipo-cotacao',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/operational-profile/price-type/price-type.module').then( m => m.PriceTypePageModule)
  },
  {
    path: 'horario-operacional',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/operational-profile/operating-hours/operating-hours.module').then( m => m.OperatingHoursPageModule)
  },
  {
    path: 'cadastro',
    canActivate: [AnonymousGuard],
    loadChildren: () => import('./pages/user/user-register/user-register.module').then( m => m.UserRegisterPageModule)
  },
  {
    path: 'recuperar-senha',
    canActivate: [AnonymousGuard],
    loadChildren: () => import('./pages/user/recover-password/recover-password.module').then( m => m.RecoverPasswordPageModule)
  },
  {
    path: 'minhas-operacoes',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/journal-operating/operation-list/operation-list.module').then( m => m.OperationListPageModule)
  },
  {
    path: 'meus-dados',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/user/user-data/user-data.module').then( m => m.UserDataPageModule)
  },
  {
    path: 'lista-chamados',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/feedback/list-feedback/list-feedback.module').then( m => m.ListFeedbackPageModule)
  },
  {
    path: 'novo-chamado',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/feedback/new-feedback/new-feedback.module').then( m => m.NewFeedbackPageModule)
  },
  {
    path: 'detalhe-chamado/:id',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/feedback/detail-feedback/detail-feedback.module').then( m => m.DetailFeedbackPageModule)
  },
  {
    path: 'editar-dados',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/user/user-edit/user-edit.module').then( m => m.UserEditPageModule)
  },
  {
    path: 'alterar-senha',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/user/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'detalhes-operacao',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/journal-operating/operation-detail/operation-detail.module').then( m => m.OperationDetailPageModule)
  },
  {
    path: 'cadastrar-operacao/:id',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/journal-operating/operation-register/operation-register.module').then( m => m.OperationRegisterPageModule)
  },
  {
    path: 'cadastrar-operacao',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/journal-operating/operation-register/operation-register.module').then( m => m.OperationRegisterPageModule)
  },
  {
    path: 'desempenho',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/report-performance/performance/performance.module').then( m => m.PerformancePageModule)
  },
  {
    path: 'alertas/:id',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/notifications/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'alertas',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/notifications/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'relatorio',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/report-performance/report/report.module').then( m => m.ReportPageModule)
  },
  {
    path: 'alertas-detalhes',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/notifications/notification-detail/notification-detail.module').then( m => m.NotificationDetailPageModule)
  },
  {
    path: 'relatorio-ativo',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/report-performance/report-by-asset/report-by-asset.module').then( m => m.ReportByAssetPageModule)
  },
  {
    path: 'calendario',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/report-performance/calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'visualizar-imagem',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./components/view-image/view-image.module').then( m => m.ViewImagePageModule)
  },
  {
    path: 'importar',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/journal-operating/import-spreadsheets/import-spreadsheets.module').then( m => m.ImportSpreadsheetsPageModule)
  },
  {
    path: 'gerar-nova-senha',
    loadChildren: () => import('./pages/user/create-new-password/create-new-password.module').then( m => m.CreateNewPasswordPageModule)
  },
  {
    path: 'tip-modal',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./components/tip-modal/tip-modal.module').then( m => m.TipModalPageModule)
  },
  {
    path: 'dicas/:id',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/tips/tips.module').then( m => m.TipsPageModule)
  },
  {
    path: 'dicas',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/tips/tips.module').then( m => m.TipsPageModule)
  },
  {
    path: 'account-confirm',
    loadChildren: () => import('./pages/user/account-confirm/account-confirm.module').then( m => m.AccountConfirmPageModule)
  },
  {
    path: 'meu-diario',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/trading-journal/trading-journal.module').then( m => m.TradingJournalPageModule)
  },
  {
    path: 'importacoes',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/journal-operating/imports/imports.module').then( m => m.ImportsPageModule)
  },
  {
    path: 'plano-trading',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/trading-plan/trading-plan.module').then( m => m.TradingPlanPageModule)
  },
  {
    path: 'correcoes',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/operational-correction/operational-correction.module').then( m => m.OperationalCorrectionPageModule)
  },
  {
    path: 'configuracoes',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/user-configuration/user-configuration.module').then( m => m.UserConfigurationPageModule)
  },
  {
    path: 'lives',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/lives/list-lives/list-lives.module').then( m => m.ListLivesPageModule)
  },
  {
    path: 'detalhe-live/:id',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/lives/detail-lives/detail-lives.module').then( m => m.DetailLivesPageModule)
  },
  {
    path: 'relatorio-mensal',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/month-report/month-report.module').then( m => m.MonthReportPageModule)
  },
  {
    path: 'splash',
    canActivate: [SplashGuard],
    loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'politica-de-privacidade',
    loadChildren: () => import('./pages/policy-and-privacy/policy-and-privacy.module').then( m => m.PolicyAndPrivacyPageModule)
  },
  {
    path: 'termos-de-uso',
    loadChildren: () => import('./pages/term-use/term-use.module').then( m => m.TermUsePageModule)
  },
  {
    path: 'manutencao',
    canActivate: [MaintenceGuard],
    loadChildren: () => import('./pages/maintence/maintence.module').then( m => m.MaintencePageModule)
  },
  {
    path: 'tutorial-report',
    loadChildren: () => import('./components/tutorial-report/tutorial-report.module').then( m => m.TutorialReportPageModule)
  },
  {
    path: 'welcome-modal',
    loadChildren: () => import('./components/welcome-modal/welcome-modal.module').then( m => m.WelcomeModalPageModule)
  },
  {
    path: 'operation-hours-modal',
    loadChildren: () => import('./components/operation-hours-modal/operation-hours-modal.module').then( m => m.OperationHoursModalPageModule)
  },
  {
    path: 'new-version-modal',
    loadChildren: () => import('./components/new-version-modal/new-version-modal.module').then( m => m.NewVersionModalPageModule)
  },
  {
    path: 'suporte',
    loadChildren: () => import('./pages/support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'remover-inscricao',
    loadChildren: () => import('./pages/unsubscribe/unsubscribe.module').then( m => m.UnsubscribePageModule)
  },
  {
    path: 'not-found',
    loadChildren: () => import('./pages/not-found/not-found.module').then( m => m.NotFoundPageModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'not-found',
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }]
})
export class AppRoutingModule { }
