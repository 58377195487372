import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

@Injectable({
    providedIn: 'root'
})
export class LoadingComponent {
    constructor(private loadingController: LoadingController) {

    }

    async presentLoading(message: string = "Aguarde...") {
        let loading = await this.loadingController.create({
            spinner: 'circular',
            message: message,
            mode: "md",
            translucent: true,
            backdropDismiss: false,
            cssClass: 'custom-loading',
        });

        await loading.present();
        return  () => loading.dismiss();
    }

    async closePresentLoading() {
        setTimeout(async () => {
            return await this.loadingController.dismiss();
        }, 1000);
    }
}