import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class SetMobileService {

    constructor(private platform: Platform) { }

    setMobile() {
        let isMobile: boolean = ((this.platform.is('android') || this.platform.is("ios")) && (this.platform.is("cordova") || this.platform.is("mobile") || this.platform.is("mobileweb")));
        //let isMobile: boolean = ((this.platform.is('android') || this.platform.is("ios")) && (this.platform.is("mobile") || this.platform.is("mobileweb")));
        localStorage.setItem('isMobile', isMobile ? "Y" : "N");
    }
}