import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PageContent } from 'src/app/models/configuration/app-configuration.model';

@Injectable({
    providedIn: 'root'
})
export class PagesContentService {

    private pages_content: PageContent[] = [];

    constructor() { }

    setPagesContent(pages_content: PageContent[]) {
        this.pages_content = pages_content;
    }

    getPagesContent() {
        return this.pages_content;
    }

    isVisible(slug: string) {
        if (this.pages_content) {
            if (this.pages_content.find((item: PageContent) => item.slug == slug && item.type == 2))
                return false;
        }

        return true;
    }

    getBlock(slug: string) {
        if(this.pages_content)
            return this.pages_content.find((item: PageContent) => item.slug == slug && item.type == 1);

        return null;
    }
}