import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { NotificationCommand } from 'src/app/commands/notification/notification.command';
import { NotificationReadModel } from 'src/app/models/notification/notification-read.model';
import { TipScheduleModel } from 'src/app/models/tip/tip-schedule.model';
import { TipModel } from 'src/app/models/tip/tip.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { TipModalPage } from '../../tip-modal/tip-modal.page';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {

  @Input() notifications: NotificationReadModel[];
  constructor(private router: Router,
    private firebaseService: FirebaseService,
    private notificationCommand: NotificationCommand,
    private modalCtrl: ModalController,
  ) { }

  notf: NotificationReadModel[];
  ngOnInit() {
    this.notf = this.notifications.slice(0, 5);
  }

  openAlerts(ntf:any = false) {
    if(ntf)
      this.showDetails(ntf);
    else
      this.router.navigate(['alertas']);
  }

  async showDetails(tipSchedule: TipScheduleModel) {
    this.firebaseService.viewScreen('alert-detail');
    this.firebaseService.eventContent('view-alert-detail', 'alert-detail', {title: tipSchedule.title, id: tipSchedule.id});

    if (tipSchedule && tipSchedule.id > 0) {
      this.notificationCommand.read(tipSchedule.id);
    }

    let tip: TipModel = new TipModel();
    tip.description = tipSchedule.description;
    tip.title = tipSchedule.title;

    let modal = await this.modalCtrl.create({
      component: TipModalPage,
      componentProps: { tip: tip, isSchedule: true },
      cssClass: 'tip-modal'
    });
    modal.present();
  }

}
