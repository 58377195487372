import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { AlertComponent } from "src/app/components/alert/alert.component";
import { LoadingComponent } from "src/app/components/loading/loading.component";
import { LoginModel } from "src/app/models/user/login.model";
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { FirebaseService } from "src/app/services/firebase/firebase.service";
import { RequestErrorService } from "src/app/services/utils/request_error.service";

@Injectable({
    providedIn: 'root'
})
export class LoginCommand {

    private api: ApiRequestService;

    constructor(
        private alert: AlertComponent,
        private loadingComponent: LoadingComponent,
        private requestError: RequestErrorService,
        private http: HttpClient,
        private firebaseService: FirebaseService,
        private platform: Platform) {
    }

    login(login: LoginModel) {

        return new Promise(async (resolve) => {
            let closeLoading = await this.loadingComponent.presentLoading("Fazendo login...");
            this.api = new ApiRequestService(this.http, "/account/token/");
            this.firebaseService.startTracePerformance("POST - /account/token/");

            this.api.post(login).subscribe({
                next: (result: any) => {
                    this.firebaseService.stopTracePerformance("POST - /account/token/");
                    closeLoading();

                    if (result.access) {

                        localStorage.setItem("token", result.access);
                        localStorage.setItem("refresh", result.refresh);
                        localStorage.setItem("user", JSON.stringify(result.user));

                        let theme: string = '';

                        if (this.platform.is('cordova'))
                            theme = result.user.theme_app == 0 || !result.user.theme_app ? 'dark' : 'light';
                        else
                            theme = result.user.theme_web == 0 || !result.user.theme_web ? 'dark' : 'light';

                        if (theme != localStorage.getItem('theme')) {
                            localStorage.setItem("theme", theme)
                            window.location.reload();
                        }

                        resolve(result.user);
                    }
                    else {
                        this.alert.presentAlert("error", "Erro", "Erro ao fazer login. Confira suas credênciais de acesso", "Fechar");
                        resolve(null);
                    }
                },
                error: (err) => {
                    this.firebaseService.stopTracePerformance("POST - /account/token/");

                    this.requestError.handleError(err, 'login');

                    if (err.status != 404 && err.status != 401) {
                        this.firebaseService.sendCrash("login-command", { url: "/account/token/", error: err });
                    }

                    closeLoading();
                    resolve(null);
                }
            });
        });
    }

    logout(token: string) {

        return new Promise(async (resolve) => {
            let closeLoading = await this.loadingComponent.presentLoading("Aguarde...");
            this.api = new ApiRequestService(this.http, "/account/authentication/user/device-token/");
            this.firebaseService.startTracePerformance("POST - /account/authentication/user/device-token/");

            this.api.post({ device_token: token }).subscribe({
                next: (result: any) => {
                    this.firebaseService.stopTracePerformance("POST - /account/authentication/user/device-token/");
                    closeLoading();

                    resolve(true);
                },
                error: (err) => {
                    closeLoading();
                    this.firebaseService.stopTracePerformance("POST - /account/authentication/user/device-token/");

                    if (err.status != 404 && err.status != 401) {
                        this.firebaseService.sendCrash("logout-command", { url: "/account/authentication/user/device-token/", error: err });
                    }

                    resolve(false);
                }
            });
        });
    }

    refreshToken(refresh: string) {

        return new Promise((resolve) => {
            this.api = new ApiRequestService(this.http, "/account/token/refresh/");
            this.firebaseService.startTracePerformance("POST - /account/token/refresh/");

            this.api.post({ refresh: refresh }).subscribe({
                next: (result: any) => {
                    this.firebaseService.stopTracePerformance("POST - /account/token/refresh/");
                    if (result.access) {

                        localStorage.setItem("token", result.access);
                        resolve(true);
                    }
                },
                error: (err) => {
                    this.firebaseService.stopTracePerformance("POST - /account/token/refresh/");
                    this.firebaseService.sendCrash("refresh-token-command", { url: "/account/token/refresh/", error: err });
                    resolve(false);
                }
            });
        });
    }
}