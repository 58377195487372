import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class SplashGuard implements CanActivate {
    constructor(private router: Router,
        public platform: Platform) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const isMaintence: boolean = (localStorage.getItem('maintence') as string) == 'Y';

        if (isMaintence) {
            this.router.navigateByUrl("/manutencao");
            return false
        } else {
            if (this.platform.is("android") && this.platform.is("cordova")) {
                return true;
            } else {
                this.router.navigate(['/home']);
                return false;
            }
        }
    }
}
