export const environment = {
  production: true,
  url: "https://api-invest-vision.azurewebsites.net/api/v1",
  //klavi_url: " https://openbanking.klavi.ai/diarioprime",
  //url: "https://api-homol.investvision.com.br/api/v1",
  klavi_url: "https://openbanking-dev.klavi.ai/diarioprime",
  firebaseConfig: {
    apiKey: "AIzaSyDAFhYzBo_ScRkKkrXWtXObwqEkEI-W2Zo",
    authDomain: "app-diario-f87a4.firebaseapp.com",
    projectId: "app-diario-f87a4",
    storageBucket: "app-diario-f87a4.appspot.com",
    messagingSenderId: "658973947245",
    appId: "1:658973947245:web:f5734ca83cd690fc6dd664",
    measurementId: "G-3Y7KL6R202"
  }
};
