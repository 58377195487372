import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Platform, PopoverController } from "@ionic/angular";
import { TutorialPopoverComponent } from "src/app/components/tutorial-popover/tutorial-popover.component";
import { ConfigTutorial, StepTutorial } from "src/app/models/tutorial/tutorial.model";

@Injectable({
    providedIn: 'root'
})
export class TutorialService {

    anchor: HTMLElement;

    currentIndex: number = 0;
    currentStep: StepTutorial;
    steps: Array<StepTutorial> = [];
    config: ConfigTutorial = new ConfigTutorial();

    intervalFindAnchor;
    tryCount: number = 0;

    constructor(
        private popoverCtrl: PopoverController,
        private router: Router,
    ) { }

    startTutorial(){
        this.currentIndex = 0;
        if(this.steps.length){
            this.currentStep = this.steps[this.currentIndex];
            this.router.navigate([this.currentStep.route]).then(() => {
                
                this.tryCount = 0;
                this.intervalFindAnchor = setInterval(() => {
                    this.anchor = document.querySelector(`[tourAnchor='${this.currentStep.anchorId}']`);
                    this.tryCount++;
                    if(this.anchor || this.tryCount > 5){
                        if(this.anchor){
                            setTimeout(() => {
                                this.anchor.click();
                            }, this.config.delayBeforeStepShow);
                        }
                        clearInterval(this.intervalFindAnchor);
                    }
                }, 500);

            });
        }
    }

    backStep() {
        if(this.currentIndex > 0){
            this.currentIndex--;
            this.currentStep = this.steps[this.currentIndex];
            this.router.navigate([this.currentStep.route]).then(() => {
                this.tryCount = 0;
                this.intervalFindAnchor = setInterval(() => {
                    this.anchor = document.querySelector(`[tourAnchor='${this.currentStep.anchorId}']`);
                    this.tryCount++;
                    if(this.anchor || this.tryCount > 5){
                        if(this.anchor)
                            this.anchor.click();

                        clearInterval(this.intervalFindAnchor);
                    }
                }, 500);
            });
        }
    }

    nextStep() {
        this.currentIndex++;
        if(this.currentIndex < this.steps.length){
            this.currentStep = this.steps[this.currentIndex];
            this.router.navigate([this.currentStep.route]).then(() => {
                this.tryCount = 0;
                this.intervalFindAnchor = setInterval(() => {
                    this.anchor = document.querySelector(`[tourAnchor='${this.currentStep.anchorId}']`);
                    this.tryCount++;
                    if(this.anchor || this.tryCount > 5){
                        if(this.anchor)
                            this.anchor.click();
                        
                        clearInterval(this.intervalFindAnchor);
                    }
                }, 500);
            });
        }
    }

    initialize(steps: Array<StepTutorial>, config: ConfigTutorial){
        let isMobile = localStorage.getItem('isMobile') == 'Y';
        this.steps = steps;
        this.config = config;

        if(isMobile){
            steps.shift();
            steps.shift();
        }
    }

    async presentTutorial(ev) {
  
      let pop = await this.popoverCtrl.create({
        component: TutorialPopoverComponent,
        cssClass: "tutorial-popover",
        componentProps: {
            title: this.currentStep.title,
            text: this.currentStep.content,
            back: this.currentIndex != 0? this.config.prevBtnTitle: "",
            next: this.currentIndex+1 != this.steps.length? this.config.nextBtnTitle: this.config.endBtnTitle,
            end: this.currentIndex+1 == this.steps.length,
        },
        event: ev,
        translucent: false,
        dismissOnSelect: true,
        side: 'bottom',
        showBackdrop: this.config.enableBackdrop,
      });
  
      pop.present();
      
    }

    
}