import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FirstAccessService {

    private isFirstAccess: boolean = false;

    setFirstAccess(value: boolean) {
        this.isFirstAccess = value;
    }

    getFirstAccess() {
        return this.isFirstAccess;
    }
}
