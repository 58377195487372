export class StepTutorial {
    anchorId: string;
    title: string;
    content: string;
    route: string;
}

export class ConfigTutorial {
    delayBeforeStepShow: number;
    enableBackdrop: boolean;
    prevBtnTitle: string;
    nextBtnTitle: string;
    endBtnTitle: string;
}