export class TipModel{
    id: number;
    title: string;
    description: string;
    tip_type: number;
    link: string;
    init_date: string;
    expired_date: string;
    is_enable: boolean;
    image: string;
}