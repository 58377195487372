import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-view-image',
  templateUrl: './view-image.page.html',
  styleUrls: ['./view-image.page.scss'],
})
export class ViewImagePage implements OnInit {

  @Input() image: string;
  @Input() showHeadbar: boolean = true;
  @Input() url: string;

  isMobile: boolean = false;

  constructor(private router: Router, 
    private modalCtrl: ModalController) {
  }

  ngOnInit() {
    this.isMobile = localStorage.getItem('isMobile') == "Y";
  }
  
  back() {
    this.modalCtrl.dismiss();
  }
}
