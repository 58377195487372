import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from "rxjs/operators";
import { Router } from '@angular/router';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { EMPTY } from 'rxjs';

@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private network: Network) { }

    intercept(request: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {

        if (this.network.type != "unknown" && this.network.type != "none") {

            // this.loginService = new LoginService(null);

            let token: string = localStorage.getItem('token') as string;

            if (!request.url.includes('/api/v1/account/token/')
                && !request.url.includes('/api/v1/account/password-reset/')
                && !request.url.includes('/api/v1/account/password-reset/confirm/')
                && !request.url.includes('/api/v1/account/account-confirm/')
                && !request.url.includes('/api/v1/account/app-configuration/')
                && (!request.url.includes('/api/v1/account/privacy-policy/') || request.url.includes('/api/v1/account/privacy-policy/auth/'))
                && (!request.url.includes('/api/v1/account/terms-use/') || request.url.includes('/api/v1/account/terms-use/auth/'))
                && !request.url.includes('/api/v1/account/user-coupon/')
                && !request.url.includes('/api/v1/coupon/user-coupon/register/')
                && !request.url.includes('/api/v1/util/unsubscribe/')) {

                request = request.clone({
                    headers: request.headers
                        .append('Authorization', `Bearer ${token}`)
                });
            }

            return next.handle(request).pipe(tap(() => {

            },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status != 401 && err.status != 403) {
                            return;
                        }
                        localStorage.clear();
                        this.router.navigate(['login']);
                    }
                }));
        } else {
            return EMPTY;
        }
    }

    // private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //     //handle your auth error or rethrow
    //     if (err.status === 401 || err.status === 403) {
    //         //navigate /delete cookies or whatever
    //         this.router.navigateByUrl(`/login`);
    //     }
    //     return;
    // }
}
