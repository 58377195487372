import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, NavController, Platform, ToastController } from "@ionic/angular";
import { AlertModel } from "src/app/models/utils/alert.model";
import { AlertEvent } from "src/app/services/events/alert.event";
import { FirebaseService } from "src/app/services/firebase/firebase.service";

@Injectable({
    providedIn: 'root'
})
export class AlertComponent {
    constructor(private alertController: AlertController,
        private location: Location,
        private router: Router,
        private platform: Platform,
        private firebaseService: FirebaseService,
        private alertEvent: AlertEvent) {
    }

    async presentAlert(type: string, title: string, description: string, text_button: any = "", redirectPage: string = null, useAbsoluteRoute: boolean = true) {
        return new Promise(async (resolve) => {
            let alert: AlertModel = {
                description: description,
                time: 5,
                type: type
            };
            this.alertEvent.setShowAlert(alert);

            resolve(true);
        });

    }

    async presentDeleteAlert(title: string, description: string, callback: Function) {
        const alert = await this.alertController.create({
            cssClass: 'alert-format delete',
            header: title,
            message: description,
            buttons: [
                {
                    text: 'Excluir',
                    handler: () => {
                        callback();
                    }
                }, {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'cancel-button'
                }]
        });

        await alert.present();
    }

    async presentCancelAlert(title: string, description: string) {
        const alert = await this.alertController.create({
            cssClass: 'alert-format primary',
            header: title,
            message: description,
            buttons: [{
                text: 'Continuar',
                role: 'cancel'
            }]
        });

        await alert.present();
    }

    async presentConfirmAlert(title: string, description: string, callback: Function, textConfirm: string = 'Continuar') {
        const alert = await this.alertController.create({
            cssClass: 'alert-format primary',
            header: title,
            message: description,
            buttons: [{
                text: 'Cancelar',
                role: 'cancel',
                cssClass: 'cancel-button'
            },
            {
                text: textConfirm,
                handler: () => {
                    callback();
                }
            }]
        });

        await alert.present();
    }
}