import { Component, Inject, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { SetMobileService } from './services/utils/set-mobile.service';
import { FirebaseRemoteConfigService } from './services/firebase/firebase-remote-config';
import { AppConfigurationCommand } from './commands/configuration/configuration.command';
import { AppConfigurationModel } from './models/configuration/app-configuration.model';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { AlertComponent } from './components/alert/alert.component';
import { Router } from '@angular/router';
import { AlertEvent } from './services/events/alert.event';
import { FirebaseMessagingService } from './services/firebase/firebase-messaging-fire.service';
import { SwPush } from '@angular/service-worker';
import { NewVersionModalPage } from './components/new-version-modal/new-version-modal.page';
import { register } from 'swiper/element/bundle';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  configuration: AppConfigurationModel;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private firebasex: FirebaseX,
    private setMobileService: SetMobileService,
    private firebaseRemoteConfigService: FirebaseRemoteConfigService,
    private configurationCommand: AppConfigurationCommand,
    private appVersion: AppVersion,
    private alertComponent: AlertComponent,
    private alertEvent: AlertEvent,
    private router: Router,
    private firebaseMessagingService: FirebaseMessagingService, 
    private swPush: SwPush, 
    private modalCtrl: ModalController,
    private matIconRegistry: MatIconRegistry,
    private domSantizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon("custon_calendar", domSantizer.bypassSecurityTrustResourceUrl("assets/icon/calendar.svg"));

  }

  ngOnInit() {

    // if (!localStorage.getItem('theme')) {
    //   localStorage.setItem('theme', 'dark');
    // }

    
    localStorage.setItem('theme', 'dark');
    document.body.setAttribute('color-theme', localStorage.getItem('theme'));

    this.setMobileService.setMobile();

    this.platform.ready().then(async (result: any) => {

      this.statusBar.overlaysWebView(false);
      this.statusBar.styleLightContent();
      this.statusBar.backgroundColorByHexString('#131821');
      this.statusBar.show();


      if ((this.platform.is('android') || this.platform.is('ios')) && this.platform.is('cordova')) {
        this.firebasex.setAnalyticsCollectionEnabled(true);
        this.firebasex.setPerformanceCollectionEnabled(true);
        this.firebasex.setCrashlyticsCollectionEnabled(true);

        //this.firebaseRemoteConfigService.init();

        if (this.platform.is('ios')) {
          this.firebasex.hasPermission().then((result) => {
            if (!result) {
              this.firebasex.grantPermission().then((result) => {
              })
            }
          });
        }

        this.firebasex.onMessageReceived().subscribe(
          {
            next: (notf) => {
              if(notf && notf.tap == "background" && notf.page){
                this.router.navigate([notf.page]);
              }
            }
          }
        )
      }

      this.getConfiguration();
    });

    if(!this.platform.is('cordova')) {
      this.firebaseMessagingService.requestPermission().subscribe(
        {
          next: (token) => { },
          error: (error) => { }
        }
      );

      this.firebaseMessagingService.receiveMessage().subscribe(
        {
          next: (payload) => {
            if (Notification.permission === 'granted') {
              const notification = new Notification(payload.notification.title, {
                body: payload.notification.body,
                icon: "assets/icon/invest-vision.svg"
              });
            }
          },
          error: (error) => { }
        }
      );
    }
  }

  getConfiguration() {
    this.configurationCommand.get().then((result: AppConfigurationModel) => {
      this.configuration = result;
      this.checkVersion();
      this.checkMaintence();
    });
  }

  checkMaintence() {
    if (this.configuration && this.configuration.under_maintence) {
      localStorage.setItem('maintence', 'Y');
    } else {
      localStorage.setItem('maintence', 'N');
    }
  }

  checkVersion() {
    if (this.platform.is("cordova")) {
      this.appVersion.getVersionNumber().then((version) => {

        if ((version < this.configuration.version) && (!this.configuration.under_maintence)) {
          setTimeout(async () => {
            let modal = await this.modalCtrl.create({
              component: NewVersionModalPage,
              cssClass: 'tip-modal',
              backdropDismiss: !this.configuration.force_update,
              componentProps: { force_update: this.configuration.force_update }
            });
        
            modal.present();
          }, 5000);
        }
      });
    }
  }
}
