import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AlterMenuSelectEvent {
    private run = new Subject<string>();

    alterRun(item: string) {
        this.run.next(item);
    }

    getRun(): Subject<string> {
        return this.run;
    }
}