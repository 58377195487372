
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertComponent } from '../../components/alert/alert.component';

@Injectable({
    providedIn: 'root'
})
export class RequestErrorService {
    constructor(private alert: AlertComponent,
        private router: Router) { }

    public handleError(err: any, type: string = '') {
        if (err.status == 401) {
            switch (type) {
                case "login":
                    this.alert.presentAlert("error", "Ops!", "Suas credenciais não foram reconhecidas", "Tentar novamente");
                    break;
                case "register":
                    this.alert.presentAlert("error", "Ops!", "Não foi possível realizar o seu cadastro", "Tentar novamente");
                    break;
                default:
                    this.router.navigateByUrl('login');
                    break;
            }
        } else if (err.status == 400) {
            if (err.error.message)
                this.alert.presentAlert("error", "Erro", err.error.message, "Fechar");
            else
                this.alert.presentAlert("error", "Erro", "Erro ao conectar no servidor. Entre em contato com o suporte.", "Fechar");
        } else if (err.status == 302) {

            switch (type) {
                case "login":
                    this.alert.presentAlert("warning", "Ops!", "Seu e-mail ainda não foi confirmado. Acesse seu e-mail para realizar a confirmação da conta.", "Tentar novamente");
                    break;
                default:
                    this.alert.presentAlert("error", "Ops!", "Não foi possível concluir a sua requisição. Entre em contato com o suporte.", "Tentar novamente");
                    break;
            }
        }
        else if (err.status != 403) {
            this.alert.presentAlert("error", "Erro", "Ocorreu um erro inesperado, mas não se preocupe o nosso time já está trabalhando para corrigir o mais breve possível. Tente novamente mais tarde.", "Fechar");
        }
    }
}



