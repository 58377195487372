import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { NotificationCommand } from 'src/app/commands/notification/notification.command';
import { NotificationReadModel } from 'src/app/models/notification/notification-read.model';
import { TipScheduleModel } from 'src/app/models/tip/tip-schedule.model';
import { TipModel } from 'src/app/models/tip/tip.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { TipModalPage } from '../tip-modal/tip-modal.page';
import { GenerateTourService } from 'src/app/services/utils/generate-tour.service';
import { WelcomeModalPage } from '../welcome-modal/welcome-modal.page';

@Component({
  selector: 'app-side-menu-modal',
  templateUrl: './side-menu-modal.component.html',
  styleUrls: ['./side-menu-modal.component.scss'],
})
export class SideMenuModalComponent implements OnInit {

  @Input() notifications: NotificationReadModel[];
  @Input() tipScheduled: TipScheduleModel[];

  notification: NotificationReadModel;
  tip: TipScheduleModel;

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private firebaseService: FirebaseService,
    private notificationCommand: NotificationCommand,
    private generateTourService: GenerateTourService,
  ) { }

  ngOnInit() {
    this.firebaseService.viewScreen('side-menu-modal');
    if(this.notifications){
      this.notification = this.notifications[0];
    }
    if(this.tipScheduled){
      this.tip = this.tipScheduled[0];
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  openAlerts(ntf:any = false) {
    if(ntf)
      this.showDetails(ntf, true);
    else{
      this.router.navigate(['alertas']);
      this.closeModal();
    }
  }

  openTips(ntf:any = false) {
    if(ntf)
      this.showDetails(ntf);
    else{
      this.router.navigate(['dicas']);
      this.closeModal();
    }
  }

  async showDetails(tipSchedule: TipScheduleModel, markRead: boolean = false) {
    this.firebaseService.viewScreen('alert-detail');
    this.firebaseService.eventContent('view-alert-detail', 'alert-detail', {title: tipSchedule.title, id: tipSchedule.id});

    if (tipSchedule && tipSchedule.id > 0 && markRead) {
      this.notificationCommand.read(tipSchedule.id);
    }

    let tip: TipModel = new TipModel();
    tip.description = tipSchedule.description;
    tip.title = tipSchedule.title;

    let modal = await this.modalCtrl.create({
      component: TipModalPage,
      componentProps: { tip: tip, isSchedule: true },
      cssClass: 'tip-modal'
    });
    modal.present();
  }

  async openWelcomeModal() {
    this.firebaseService.eventClick(`side-menu-modal-tutorial`, 'side-menu-modal');
    this.router.navigate([`/home`]);
    this.generateTourService.generate();
    let modal = await this.modalCtrl.create({
      component: WelcomeModalPage,
      cssClass: 'tip-modal'
    });

    this.closeModal();
    modal.present();
  }

}
