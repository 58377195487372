import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AlterImageProfileEvent {
    private run = new Subject<void>();

    alterRun() {
        this.run.next();
    }

    getRun(): Subject<void> {
        return this.run;
    }
}