import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { IonAccordionGroup, ModalController, Platform } from '@ionic/angular';
import { LoginCommand } from 'src/app/commands/authenticate/login.command';
import { UserCommand } from 'src/app/commands/user/user.command';
import { UserModel } from 'src/app/models/user/user.model';
import { AlterMenuSelectEvent } from 'src/app/services/events/alter-menu-select.event';
import { FeedbackMessageEvent } from 'src/app/services/events/feedback-message.event';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { GenerateTourService } from 'src/app/services/utils/generate-tour.service';
import { AlertComponent } from '../alert/alert.component';
import { WelcomeModalPage } from '../welcome-modal/welcome-modal.page';
import { PageContent } from 'src/app/models/configuration/app-configuration.model';
import { PagesContentService } from 'src/app/services/utils/pages-content.service';
import { AlertEvent } from 'src/app/services/events/alert.event';
import { FirstAccessService } from 'src/app/services/utils/first-access.service';
import { ProfileService } from 'src/app/services/utils/profile.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() pages_content: PageContent[] = [];
  @ViewChild('accordionPerformance', { static: true }) accordionPerformance: any;
  @ViewChild('accordionOperationProfile', { static: true }) accordionOperationProfile: any;
  @ViewChild('accordionEducation', { static: true }) accordionEducation: any;

  menuItem = 'home';
  theme: string;
  isMobile: boolean;

  constructor(private route: Router,
    private network: Network,
    private alertComponent: AlertComponent,
    private firebaseService: FirebaseService,
    private alterMenuSelect: AlterMenuSelectEvent,
    private platform: Platform,
    private loginCommand: LoginCommand,
    private userCommand: UserCommand,
    private generateTourService: GenerateTourService,
    private modalCtrl: ModalController,
    private feedbackMessageEvent: FeedbackMessageEvent,
    private alertEvent: AlertEvent,
    private firstAccessService: FirstAccessService,
    private pagesContentService: PagesContentService,
    private profileService: ProfileService,
  ) { }

  ngOnInit() {
    this.theme = localStorage.getItem('theme');
    this.isMobile = localStorage.getItem('isMobile') == "Y";

    this.alterMenuSelect.getRun().subscribe((page: string) => {
      this.menuItem = page;
      const nativePerformance = this.accordionPerformance;
      const nativeOperationProfile = this.accordionOperationProfile;
      const nativeEducation = this.accordionEducation;

      if (page == 'calendario' || page == 'desempenho' || page == 'relatorio' || page == 'relatorio-ativo')
        nativePerformance.value = 'performance';
      else nativePerformance.value = undefined;

      if (page == 'tipo-cotacao' || page == 'relatorio' || page == 'horario-operacional')
        nativeOperationProfile.value = 'operationProfile';
      else nativeOperationProfile.value = undefined;

      if (page == 'lives')
        nativeEducation.value = 'education';
      else nativeEducation.value = undefined
        
    })
  }

  selectPage(page) {
    this.firebaseService.eventClick(`menu-${page}`, 'menu');
    let page_block = this.pagesContentService.getBlock(page);
    
    if(page_block){
      this.alertComponent.presentAlert("warning", "Conteúdo indisponível", page_block.message);
    }else{
      if (this.network.type != "unknown" && this.network.type != "none" || page == 'home') {
        this.menuItem = page;      
        this.route.navigate([`/${page}`]);
      } else {
        this.alertComponent.presentAlert("warning", "Sem conexão", "Verifique a sua conexão com a internet.", "Continuar");
      }
    }
  }

  async logout() {

    let token: string = '';

    token = await this.firebaseService.getToken() as string;
    await this.loginCommand.logout(token) as boolean;

    this.firebaseService.eventClick(`menu-logout`, 'menu');
    localStorage.clear();
    localStorage.setItem("theme", this.theme);
    this.route.navigateByUrl('/login');
  }

  alterTheme(theme: string) {
    if (theme != this.theme) {

      let user: UserModel = JSON.parse(localStorage.getItem("user")) as UserModel;

      if (this.platform.is('cordova'))
        user.theme_app = theme == 'dark' ? 0 : 1;
      else
        user.theme_web = theme == 'dark' ? 0 : 1;


      this.userCommand.updateTheme(theme).then((result: boolean) => {

        if (result) {
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('theme', 'dark');
          window.location.reload();
        }
      }).catch((error: any) => console.log(error));

    }
  }

  open(page: string) {
    window.open(page, '_blank');
  }
}
