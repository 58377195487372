import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserModel } from 'src/app/models/user/user.model';
import { TutorialService } from 'src/app/services/tutorial/tutorial.service';

@Component({
  selector: 'app-welcome-modal',
  templateUrl: './welcome-modal.page.html',
  styleUrls: ['./welcome-modal.page.scss'],
})
export class WelcomeModalPage implements OnInit {

  user: UserModel;

  constructor(private modalCtrl: ModalController, private tourService: TutorialService) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user')) as UserModel;

    if(this.user) {
      this.user.name = this.user.name.split(' ')[0];
    }
  }

  startTour() {
    this.closeModal();
    this.tourService.startTutorial();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
