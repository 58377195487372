import { Injectable } from "@angular/core";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { FirebaseX } from "@awesome-cordova-plugins/firebase-x/ngx";
import { Platform } from "@ionic/angular";
import { AngularFirePerformance } from '@angular/fire/compat/performance';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    arrayTraces: any[] = [];

    constructor(private firebasex: FirebaseX, private platform: Platform,
        private angularMessaging: AngularFireMessaging,
        private angularAnalytics: AngularFireAnalytics,
        private angularPerformance: AngularFirePerformance) {

    }

    viewScreen(page: string) {
        if ((this.platform.is('android') || this.platform.is('ios')) && this.platform.is('cordova')) {
            this.firebasex.setScreenName(page);
        } else {
            this.angularAnalytics.setCurrentScreen(page);
        }
    }

    eventClick(description: string, page: string) {
        if ((this.platform.is('android') || this.platform.is('ios')) && this.platform.is('cordova')) {
            this.firebasex.logEvent("event_click", {
                button_name: description.replace("_", "-"),
                page: page,
                platform: this.platform.is("android") ? "android" : "ios",
            });
        } else {
            this.angularAnalytics.logEvent("event_click", {
                button_name: description.replace("_", "-"),
                page: page,
                platform: "web",
            });
        }
    }

    eventContent(type: string, page: string, content: any) {

        if ((this.platform.is('android') || this.platform.is('ios')) && this.platform.is('cordova')) {
            this.firebasex.logEvent("view_content", {
                event_type: type,
                page: page,
                platform: this.platform.is("android") ? "android" : "ios",
                event_content: content
            });
        } else {
            this.angularAnalytics.logEvent("view_content", {
                event_type: type,
                page: page,
                platform: "web",
                event_content: content
            });
        }
    }

    sendCrash(title: string, content: any) {
        if ((this.platform.is('android') || this.platform.is('ios')) && this.platform.is('cordova')) {
            this.firebasex.logError(JSON.stringify({ title: title, content: content }));
        }
    }

    async startTracePerformance(name: string) {
        if ((this.platform.is('android') || this.platform.is('ios')) && this.platform.is('cordova')) {
            this.firebasex.startTrace(name);
        } else {
            const trace = await this.angularPerformance.trace('GET /account/app-configuration');
            await trace.start();

            this.arrayTraces.push({trace: trace, name: name});
        }
    }

    async stopTracePerformance(name: string) {
        if ((this.platform.is('android') || this.platform.is('ios')) && this.platform.is('cordova')) {
            this.firebasex.stopTrace(name);
        } else {
            let pos: number = this.arrayTraces.findIndex(x => x.name == name);

            if(pos >= 0) {
                await this.arrayTraces[pos].trace.stop();
                this.arrayTraces = this.arrayTraces.splice(pos, 0);
            }
        }
    }

    getToken() {

        return new Promise(async (resolve, reject) => {
            if ((this.platform.is('android') || this.platform.is('ios')) && this.platform.is('cordova')) {
                const token = await this.firebasex.getToken();
                resolve(token);
            } else {
                if (Notification.permission == 'granted') {
                    await this.angularMessaging.getToken.subscribe((token: any) => {
                        resolve(token);
                    });
                }
                else {
                    resolve('');
                }
            }
        })
    }
}