import { Injectable } from '@angular/core';
import { UserModel } from 'src/app/models/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

    get src() {
        let user: UserModel = JSON.parse(localStorage.getItem("user")) as UserModel;
        return user && user.profile_image? user.profile_image: "";
    }

    get name() {
        let user: UserModel = JSON.parse(localStorage.getItem("user")) as UserModel;
        return user && user.name? user.name: "";
    }

    get valid() {
        let user: UserModel = JSON.parse(localStorage.getItem("user")) as UserModel;
        return user != null;
    }
}
