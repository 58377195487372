import { Injectable } from '@angular/core';
import { TutorialService } from '../tutorial/tutorial.service';

@Injectable({
  providedIn: 'root'
})
export class GenerateTourService {

  constructor(private tourService: TutorialService) { }

  generate() {
    
    this.tourService.steps = [];
    this.tourService.initialize([{
      anchorId: 'step.web.1',
      title: 'Importar operações',
      content: 'Aqui você tem acesso rápido para importar as suas operações, conectando na sua corretora ou importando arquivos extraídos direto da sua plataforma de operações',
      route: 'home'
    }, {
      anchorId: 'step.web.2',
      title: 'Importação manual',
      content: 'Selecione o tipo de arquivo que deseja importar, siga as instruções e de maneira rápida tenha todas as suas operações dentro do diário Invest Vision',
      route: 'importar',
    }, {
      anchorId: 'step.web.3',
      title: 'Meu diário',
      content: 'Visualize todos os seus resultados diários em formato de gráfico',
      route: 'meu-diario',
    }, {
      anchorId: 'step.web.4',
      title: 'Minhas operações',
      content: 'Tenha acesso a sua lista completa de operações',
      route: 'minhas-operacoes',
    }, {
      anchorId: 'step.web.5',
      title: 'Calendário',
      content: 'Tenha uma visão geral de cada dia do seu mês',
      route: 'calendario',
    }, {
      anchorId: 'step.web.6',
      title: 'Desempenho',
      content: 'Acompanhe de perto a sua evolução, o seu desempenho diário e o seu desempenho mensal',
      route: 'desempenho',
    }, {
      anchorId: 'step.web.7',
      title: 'Suporte',
      content: 'E qualquer dúvida ou sugestões referente a plataforma, entre em contato conosco, o nosso time está pronto para ouvir você 😉',
      route: 'lista-chamados',
    }
    ], {
      delayBeforeStepShow: 1200,
      enableBackdrop: false,
      prevBtnTitle: "Anterior",
      nextBtnTitle: "Próximo",
      endBtnTitle: "Finalizar"
    });
  }
}
