import { Component, Input, OnInit } from '@angular/core';
import { TutorialService } from 'src/app/services/tutorial/tutorial.service';

@Component({
  selector: 'app-tutorial-popover',
  templateUrl: './tutorial-popover.component.html',
  styleUrls: ['./tutorial-popover.component.scss'],
})
export class TutorialPopoverComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() next: string;
  @Input() back: string;
  @Input() end: boolean = false;

  constructor(public tutorialService: TutorialService) { }

  ngOnInit() {}

  backStep(){
    this.tutorialService.backStep();
  }
  
  nextStep(){
    this.tutorialService.nextStep();
  }

}
