import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class FeedbackMessageEvent {
    private hasMessage: boolean = false;

    setHasMessage(hasMessage: boolean) {
        this.hasMessage = hasMessage;
    }

    getHasMessage(): boolean {
        return this.hasMessage;
    }
}