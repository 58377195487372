import { Injectable } from "@angular/core";
import { TipModel } from "src/app/models/tip/tip.model";
import { RequestErrorService } from "src/app/services/utils/request_error.service";
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { HttpClient } from "@angular/common/http";
import { FirebaseService } from "src/app/services/firebase/firebase.service";

@Injectable({
    providedIn: 'root'
})
export class TipReadCommand {

    private api: ApiRequestService;
    
    constructor(
        private requestError: RequestErrorService,
        private http: HttpClient,
        private firebaseService: FirebaseService) {
        this.api = new ApiRequestService(this.http, "/account/tips-read/");
    }

    setReaded(tip: TipModel) {
        return new Promise(async (resolve) => {
            this.firebaseService.startTracePerformance("POST - /account/tips-read/");

            this.api.post<any>({tip: tip.id}).subscribe({
                next: (result: TipModel) => {
                    this.firebaseService.stopTracePerformance("POST - /account/tips-read/");
                    resolve(result);
                },
                error: (err) => {
                    this.firebaseService.stopTracePerformance("POST - /account/tips-read/");
                    resolve(null);

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                        this.firebaseService.sendCrash("set-readed-tip-command", { url: `/account/tips-read/`, id: tip.id, error: err });
                    }
                }
            });
        });
    }

    getReaded() {
        return new Promise(async (resolve) => {

            this.firebaseService.startTracePerformance("GET - /account/tips-read/");
            this.api.get<any>().subscribe({
                next: (result: any) => {
                    this.firebaseService.stopTracePerformance("GET - /account/tips-read/");
                    resolve(result.results);
                },
                error: (err) => {
                    this.firebaseService.stopTracePerformance("GET - /account/tips-read/");
                    resolve(null);

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                        this.firebaseService.sendCrash("get-readed-tip-command", { url: `/account/tips-read/`, error: err });
                    }
                }
            });
        });
    }
}
