import { Injectable } from "@angular/core";
import { AlertComponent } from "src/app/components/alert/alert.component";
import { LoadingComponent } from "src/app/components/loading/loading.component";
import { UserModel } from "src/app/models/user/user.model";
import { RequestErrorService } from "src/app/services/utils/request_error.service";

import * as moment from 'moment';
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { HttpClient } from "@angular/common/http";
import { FirebaseService } from "src/app/services/firebase/firebase.service";
import { AlterImageProfileEvent } from "src/app/services/events/alter-image-profile.event";
import { Platform } from "@ionic/angular";
import { UserRegisterModel } from "src/app/models/user/user-register.model";

@Injectable({
    providedIn: 'root'
})
export class UserCommand {

    private api: ApiRequestService;

    constructor(
        private loadingComponent: LoadingComponent,
        private alert: AlertComponent,
        private requestError: RequestErrorService,
        private http: HttpClient,
        private firebaseService: FirebaseService,
        private alterImageProfileEvent: AlterImageProfileEvent,
        private platform: Platform) {
    }

    create(user: UserRegisterModel) {
        return new Promise(async (resolve) => {
            let closeLoading = await this.loadingComponent.presentLoading("Enviando seu cadastro...");

            this.api = new ApiRequestService(this.http, "/coupon/user-coupon/register/");
                
            this.firebaseService.startTracePerformance("POST - /coupon/user-coupon/register/");
            this.api.post<any>(user).subscribe({
                next: (result: any) => {
                    this.firebaseService.stopTracePerformance("POST - /coupon/user-coupon/register/");
                    this.loadingComponent.closePresentLoading();
                    this.alert.presentAlert("success", "Concluído!", "Cadastro enviado com sucesso", "Continuar");
                    closeLoading();
                    resolve(true);
                },
                error: (err) => {
                    this.firebaseService.stopTracePerformance("POST - /coupon/user-coupon/register/");
                    this.loadingComponent.closePresentLoading();

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                        this.firebaseService.sendCrash("user-register-command", { url: `/coupon/user-coupon/register/`, error: err });
                    }

                    resolve(false);
                }
            });
        });
    }

    get(showLoading: boolean = true) {
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, "/account/authentication/user/");
            let closeLoading;

            if (showLoading)
                closeLoading = await this.loadingComponent.presentLoading("Consultando informações do seu perfil...");

            this.firebaseService.startTracePerformance("GET - account/authentication/user/");
            this.api.get<any>().subscribe({
                next: (result: any) => {
                    this.firebaseService.stopTracePerformance("GET - account/authentication/user/");
                    localStorage.setItem("user", JSON.stringify(result));
                    this.refreshTheme(result);

                    this.alterImageProfileEvent.alterRun();

                    if (showLoading)
                        closeLoading();

                    resolve(result);
                },
                error: (err) => {
                    this.firebaseService.stopTracePerformance("GET - account/authentication/user/");

                    if (showLoading)
                        closeLoading();

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                        this.firebaseService.sendCrash("get-user-command", { url: `/account/authentication/user/`, error: err });
                    }

                    resolve(null);
                }
            });
        });
    }

    update(user: UserModel, editDate: boolean = true, editImage: boolean = false, showAlert: boolean = true) {
        this.api = new ApiRequestService(this.http, "/account/authentication/user/info/");
        return new Promise(async (resolve) => {
            let closeLoading = await this.loadingComponent.presentLoading("Aguarde enquanto enviamos seus dados atualizados...");

            if (editDate)
                user.birth_date = moment(user.birth_date, "DD/MM/YYYY").format('YYYY-MM-DD');

            if(user.document_number)
                user.document_number = user.document_number.replace(/[^a-zA-Z0-9]/g, "");

            let formData = new FormData();

            for (let key of Object.keys(user)) {
                if (user[key] || key == 'profile_image') {
                    if (key != 'profile_image')
                        formData.append(key, user[key]);
                    else if (editImage) {
                        if (user[key]) {
                            formData.append(key, user[key], 'profile_picture.jpg');
                        } else {
                            formData.append(key, user[key]);
                        }
                    }
                }
            }

            this.firebaseService.startTracePerformance("PUT - /account/authentication/user/info/");
            this.api.put<UserModel>(formData, false).subscribe({
                next: async (result: UserModel) => {
                    this.firebaseService.stopTracePerformance("PUT - /account/authentication/user/info/");
                    closeLoading();
                    if (result) {
                        localStorage.setItem("user", JSON.stringify(result));
                        this.refreshTheme(result);

                        if (showAlert)
                            await this.alert.presentAlert("success", "Enviado!", "Os seus dados foram atualizados com sucesso", "Continuar");

                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
                error: (err) => {
                    this.firebaseService.stopTracePerformance("PUT - /account/authentication/user/info/");
                    resolve(false);

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                        this.firebaseService.sendCrash("update-user-command", { url: `/account/authentication/user/info/`, id: user.id, error: err });
                    }

                    closeLoading();
                }
            });
        });
    }

    updateDeviceToken(token: string, showAlert: boolean = true) {
        this.api = new ApiRequestService(this.http, "/account/authentication/user/device-token/update/");
        return new Promise(async (resolve) => {

            this.firebaseService.startTracePerformance("POST - /account/authentication/user/device-token/update/");
            this.api.post<any>({"device_token": token}).subscribe({
                next: async (result) => {
                    this.firebaseService.stopTracePerformance("POST - /account/authentication/user/device-token/update/");
                    if (result) {
                        localStorage.setItem('lastTokenUpdate', new Date().toISOString());

                        if (showAlert)
                            await this.alert.presentAlert("success", "Enviado!", "Os seus dados foram atualizados com sucesso", "Continuar");

                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
                error: (err) => {
                    this.firebaseService.stopTracePerformance("POST - /account/authentication/user/device-token/update/");
                    resolve(false);

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                        this.firebaseService.sendCrash("update-user-command", { url: `/account/authentication/user/device-token/update/`, token: token, error: err });
                    }
                }
            });
        });
    }

    updateTheme(theme: string) {
        let url: string = "/account/authentication/user/theme/";
        this.api = new ApiRequestService(this.http, url);

        return new Promise(async (resolve) => {
            let closeLoading = await this.loadingComponent.presentLoading("Atualizando tema...");

            this.firebaseService.startTracePerformance(`PATCH - ${url}`);

            let data: any;

            if (this.platform.is("cordova"))
                data = { theme_app: theme == 'dark' ? 0 : 1 };
            else
                data = { theme_web: theme == 'dark' ? 0 : 1 };

            this.api.patch<any>(data, false).subscribe({
                next: async (result: UserModel) => {
                    this.firebaseService.stopTracePerformance(`PATCH - ${url}`);
                    closeLoading();

                    if (result) {
                        localStorage.setItem("user", JSON.stringify(result));
                        this.refreshTheme(result);

                        resolve(true);
                    } else {
                        resolve(false);
                    }
                },
                error: (err) => {
                    this.firebaseService.stopTracePerformance(`PATCH - ${url}`);
                    resolve(false);

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                        this.firebaseService.sendCrash("update-user-command", { url: url, theme: theme, error: err });
                    }

                    closeLoading();
                }
            });
        });
    }

    activateAccount(token: string) {
        return new Promise(async (resolve) => {
            this.api = new ApiRequestService(this.http, `/account/account-confirm/${token}/`);
            let closeLoading = await this.loadingComponent.presentLoading("Aguarde, estamos ativando a sua conta...");

            this.firebaseService.startTracePerformance("GET - /account/account-confirm/");
            this.api.get<any>().subscribe({
                next: (result: any) => {
                    this.firebaseService.stopTracePerformance("GET - /account/account-confirm/");
                    closeLoading();
                    resolve(result);
                },
                error: (err) => {
                    this.firebaseService.stopTracePerformance("GET - /account/account-confirm/");
                    this.firebaseService.sendCrash("active-account-command", { url: `/account/account-confirm/${token}`, error: err });
                    closeLoading();
                    resolve(false);
                }
            });
        });
    }

    private refreshTheme(user: UserModel) {
        let theme: string;
        if (this.platform.is('cordova'))
            theme = user.theme_app == 0 || !user.theme_app ? 'dark' : 'light';
        else
            theme = user.theme_web == 0 || !user.theme_web ? 'dark' : 'light';

        if (!localStorage.getItem('theme')) {
            localStorage.setItem("theme", theme);
        } else if (theme != localStorage.getItem('theme')) {
            // localStorage.setItem("theme", theme);
            // window.location.reload();
        }
    }
}