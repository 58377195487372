import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from './services/api/http.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaskPipe } from 'ngx-mask';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { MenuComponent } from './components/menu/menu.component';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { FullCalendarModule } from '@fullcalendar/angular'; 
import { Network } from  '@awesome-cordova-plugins/network/ngx';
import { PopoverComponent } from './components/headbar/popover/popover.component';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertComponent } from './components/alert/alert/alert.component';
import { FirebaseMessagingService } from './services/firebase/firebase-messaging-fire.service';
import { FirebaseModule } from './firebase.module';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { PreviusReportModalComponent } from './components/previus-report-modal/previus-report-modal.component';
import { BedgeWarningComponent } from './components/bedge-warning/bedge-warning.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { SideMenuModalComponent } from './components/side-menu-modal/side-menu-modal.component';
import { TutorialPopoverComponent } from './components/tutorial-popover/tutorial-popover.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

registerLocaleData(localePt);

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        AppComponent,
        MenuComponent,
        AlertComponent,
        PopoverComponent,
        SideMenuModalComponent,
        TutorialPopoverComponent,
    ],
    imports: [
        RouterModule,
        FirebaseModule,
        BrowserModule,
        IonicModule.forRoot({
            mode: "md",
            swipeBackEnabled: true,
        }),
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        FullCalendarModule,
        BrowserAnimationsModule,
        CKEditorModule,
    ],
    providers: [
        AppVersion,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true,
        },
        {
            provide: LOCALE_ID, useValue: 'pt-BR'
        },
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        FirebaseX,
        MaskPipe,
        Camera,
        StatusBar,
        Network,
        FirebaseMessagingService,
        InAppBrowser
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
