import { Injectable } from "@angular/core";
import { FirebaseX } from "@awesome-cordova-plugins/firebase-x/ngx";
import { Platform } from "@ionic/angular";
import { FirebaseService } from "./firebase.service";

@Injectable({
    providedIn: 'root'
})
export class FirebaseRemoteConfigService {

    constructor(
        private firebasex: FirebaseX,
        private platform: Platform,
        private firebaseService: FirebaseService) {

    }

    async init() {
        if ((this.platform.is('android') || this.platform.is('ios')) && this.platform.is('cordova')) {
            this.setDefaults();

            let settings = {
                fetchTimeout: 1,
                minimumFetchInterval: 1,
            };

            try {
                await this.firebasex.setConfigSettings(settings);
                await this.firebasex.fetch();
                await this.firebasex.activateFetched();
            } catch (error) {
            }
        }
    }

    private setDefaults() {
        if ((this.platform.is('android') || this.platform.is('ios')) && this.platform.is('cordova')) {

            var defaults = {
                view_button_subscription: false,
            }

            this.firebasex.setDefaults(defaults);
        }
    }

    async getViewButtonSubscription() {
        if ((this.platform.is('android') || this.platform.is('ios')) && this.platform.is('cordova')) {
            // let result: string = await this.firebasex.getValue('view_button_subscription');
            return false;
        } else {
            return true; //retorna true se for Web
        }
    }
}