import { Component, Input, OnInit } from '@angular/core';
import { AlertModel } from 'src/app/models/utils/alert.model';
import { AlertEvent } from 'src/app/services/events/alert.event';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  alert: AlertModel;
  public progress = 1;

  constructor(private alertEvent: AlertEvent) {
    
  }

  ngOnInit() {
    this.alert = this.alertEvent.getAlert();
    this.timeProgressBar();
  }

  timeProgressBar() {
    setTimeout(() => {
      this.progress -= 0.01;
      
      if(this.progress < 0) {
        this.close();
      } else {
        this.timeProgressBar();
      }
    }, (this.alert.time * 10))
  }

  close() {
    this.alertEvent.setCloseAlert();
  }
}
