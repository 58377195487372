import { Component, Input, OnInit, Optional } from '@angular/core';
import { IonRouterOutlet, ModalController, NavController, Platform } from '@ionic/angular';
import { UserModel } from 'src/app/models/user/user.model';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { NavOptions } from '@ionic/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';


@Component({
  selector: 'app-new-version-modal',
  templateUrl: './new-version-modal.page.html',
  styleUrls: ['./new-version-modal.page.scss'],
})
export class NewVersionModalPage implements OnInit {

  @Input() force_update: boolean;

  user: UserModel;

  constructor(private platform: Platform,
    private firebaseService: FirebaseService,
    private modalCtrl: ModalController,
    private iab: InAppBrowser
    ) { }

  ngOnInit() {
    this.firebaseService.viewScreen('new-version');
    this.user = JSON.parse(localStorage.getItem("user")) as UserModel;
    this.user.name = this.user.name.split(' ')[0];
  }

  ionViewDidEnter() {

    if (this.force_update)
      this.disableBackButton();
  }

  disableBackButton() {
    document.addEventListener('ionBackButton', (ev: any) => {
      ev.detail.register(0, () => { });
    });
  }

  closeModal() {
    this.firebaseService.eventClick(`close-modal`, 'new-version');
    this.modalCtrl.dismiss();
  }

  update() {
    let isAndroid: boolean = this.platform.is("android");

    this.firebaseService.eventClick(`update-app-${isAndroid ? 'android' : 'ios'}`, 'new-version');

    if (isAndroid)
      window.open('https://play.google.com/store/apps/details?id=com.diariodetrading.app', '_blank');
    else
      this.iab.create('https://apps.apple.com/us/app/invest-vision/id1641972879', '_system');
  }
}
