import { Injectable } from "@angular/core";
import { LoadingComponent } from "src/app/components/loading/loading.component";
import { RequestErrorService } from "src/app/services/utils/request_error.service";
import { ApiRequestService } from "src/app/services/api/api-request.service";
import { HttpClient } from "@angular/common/http";
import { FirebaseService } from "src/app/services/firebase/firebase.service";
import { AppConfigurationModel } from "src/app/models/configuration/app-configuration.model";
import { PagesContentService } from "src/app/services/utils/pages-content.service";

@Injectable({
    providedIn: 'root'
})
export class AppConfigurationCommand {

    private api: ApiRequestService;

    constructor(
        private loadingComponent: LoadingComponent,
        private requestError: RequestErrorService,
        private http: HttpClient,
        private firebaseService: FirebaseService,
        private pagesContentService: PagesContentService) {
    }

    get(showLoading: boolean = false, text_loading: string = '') {
        return new Promise(async (resolve) => {
            let url: string = "/account/app-configuration/";
            this.api = new ApiRequestService(this.http, url);
            
            let closeLoading;

            if (showLoading)
                closeLoading = await this.loadingComponent.presentLoading(text_loading);

            this.firebaseService.startTracePerformance(`GET - ${url}`);
            this.api.get<AppConfigurationModel>().subscribe({
                next: (result: AppConfigurationModel) => {
                    this.firebaseService.stopTracePerformance(`GET - ${url}`);

                    if (showLoading)
                        closeLoading();

                    if(result) 
                        this.pagesContentService.setPagesContent(result.pages_content)
                    
                    resolve(result);
                },
                error: (err) => {
                    this.firebaseService.stopTracePerformance(`GET - ${url}`);

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                        this.firebaseService.sendCrash("get-user-command", { url: url, error: err });
                    }

                    if (showLoading)
                        closeLoading();

                    resolve(null);
                }
            });
        });
    }
}