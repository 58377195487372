import { Injectable } from "@angular/core";
import { LoadingComponent } from "src/app/components/loading/loading.component";
import { RequestErrorService } from "src/app/services/utils/request_error.service";

import { ApiRequestService } from "src/app/services/api/api-request.service";
import { HttpClient } from "@angular/common/http";
import { FirebaseService } from "src/app/services/firebase/firebase.service";

@Injectable({
    providedIn: 'root'
})
export class NotificationCommand {

    private api: ApiRequestService;

    constructor(
        private loadingComponent: LoadingComponent,
        private requestError: RequestErrorService,
        private http: HttpClient,
        private firebaseService: FirebaseService) {
    }

    get(offset: number = 0, showLoading: boolean = true, init_date: string = '', end_date: string = '', limit: number = 15, is_read: boolean = null) {
        return new Promise(async (resolve) => {

            init_date = init_date? `&init_date=${init_date}`: '';
            end_date = end_date? `&end_date=${end_date}`: '';
            let filterRead = is_read != null? `&is_read=${is_read?'True':'False'}`: '';
            let closeLoading;

            if (showLoading)
                closeLoading = await this.loadingComponent.presentLoading("Buscando suas notificações...");

            this.firebaseService.startTracePerformance("GET - /alert/user/");

            this.api = new ApiRequestService(this.http, `/alert/user/?offset=${offset}&limit=${limit}${init_date}${end_date}${filterRead}`);

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    this.firebaseService.stopTracePerformance("GET - /alert/user/");

                    if (showLoading)
                        closeLoading();

                    resolve(result);
                },
                error: (err) => {
                    this.firebaseService.stopTracePerformance("GET - /alert/user/");

                    resolve(null);

                    if (showLoading)
                        closeLoading();

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                        this.firebaseService.sendCrash("get-alert-command", { url: `/alert/user/`, error: err });
                    }
                }
            });
        });
    }

    getByDate(date: string = '', showLoading: boolean = true, limit: number = 15) {
        return new Promise(async (resolve) => {

            date = date? `&date=${date}`: '';
            let closeLoading;

            if (showLoading)
                closeLoading = await this.loadingComponent.presentLoading("Buscando suas notificações...");

            this.firebaseService.startTracePerformance("GET - /alert/user/");

            this.api = new ApiRequestService(this.http, `/alert/user/?limit=${limit}${date}`);

            this.api.get<any>().subscribe({
                next: (result: any) => {
                    this.firebaseService.stopTracePerformance("GET - /alert/user/");

                    if (showLoading)
                        closeLoading();

                    resolve(result);
                },
                error: (err) => {
                    this.firebaseService.stopTracePerformance("GET - /alert/user/");

                    resolve(null);

                    if (showLoading)
                        closeLoading();

                    if (err.status != 404) {
                        this.requestError.handleError(err);
                        this.firebaseService.sendCrash("get-alert-command", { url: `/alert/user/`, error: err });
                    }
                }
            });
        });
    }

    read(idAlert: number) {
        return new Promise(async (resolve) => {
            this.firebaseService.startTracePerformance("POST - /alert/user/");

        this.api = new ApiRequestService(this.http, "/alert/user/");

            this.api.post<any>({alert: idAlert}).subscribe({
                next: (result: any) => {
                    this.firebaseService.stopTracePerformance("POST - /alert/user/");
                    resolve(true);
                },
                error: (err) => {
                    this.firebaseService.stopTracePerformance("POST - /alert/user/");
                    resolve(false);

                    if (err.status != 404) {
                        this.firebaseService.sendCrash("read-alert-command", { url: `/alert/user/`, error: err });
                    }
                }
            });
        });
    }
}