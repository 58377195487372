import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { TipReadCommand } from 'src/app/commands/tips/tip-read-command.service';
import { TipModel } from 'src/app/models/tip/tip.model';
import { ViewImagePage } from '../view-image/view-image.page';

@Component({
  selector: 'app-tip-modal',
  templateUrl: './tip-modal.page.html',
  styleUrls: ['./tip-modal.page.scss'],
})
export class TipModalPage implements OnInit, OnDestroy  {

  @Input() tip: TipModel;
  @Input() isSchedule: boolean = false;
  smallScreen = true;
  videoLink;

  constructor(private modalCtrl: ModalController,
    private tipReadCommand: TipReadCommand,
    private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.smallScreen = window.innerWidth <= 750;
    
    if (this.tip) {
      if(this.tip.link)
      this.videoLink = this.secureUrl(this.tip.link);
    }
  }

  ngOnDestroy() {
    this.setCloseApi();
  }

  async openImage(src) {
    let modal = await this.modalCtrl.create({
      component: ViewImagePage,
      componentProps: { image: src, showHeadbar: this.smallScreen }
    });

    modal.present();
  }

  secureUrl(url: string = "") {
    if (url.includes('watch?v=')) url = url.replace('watch?v=', 'embed/');
    if (url.includes('youtu.be')) url = url.replace('youtu.be', 'youtube.com/embed');
    if (url.includes('&')) url = url.substring(0, url.indexOf('&'));
    if (!url.includes('embed')) {
      url = '';
    }
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  closeModal() {
    this.setCloseApi();

    this.modalCtrl.dismiss().catch(error => {
    });
  }

  setCloseApi() {
    if (!this.isSchedule) {
      if (!this.tip.expired_date)
        this.tipReadCommand.setReaded(this.tip);
    }
  }
}
